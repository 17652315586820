// Import Custom Elements
import './input.js';
import './inputIri.js';
import './datepicker.js';

// Import rdflib.js
// https://github.com/linkeddata/rdflib.js/
import * as $rdf from 'rdflib';
import {
    ArrayIndexOf
} from 'rdflib/lib/utils';

/**
 * This is the Builder Class.
 * @example
 * let builder = new Builder();
 */
export class Builder {

    constructor(base, dataUrl, shapeUrl, queryUrl) {

        this.base = base;
        this.dataUrl = dataUrl;
        this.shapeUrl = shapeUrl;
        this.queryUrl = queryUrl;

        this._dataContent = '';
        this._shapeContent = '';
        this._queryContent = '';

        this._queryResult = {};

        // Define property types
        this.propType = {
            single: 1,
            uri: 2,
            class: 3,
            multipleOr: 4,
            enumeration: 5
        };

        // Declare necessary rdf namespaces
        this.RDF = $rdf.Namespace('http://www.w3.org/1999/02/22-rdf-syntax-ns#');
        this.SHACL = $rdf.Namespace('http://www.w3.org/ns/shacl#');
        this.XSD = $rdf.Namespace('http://www.w3.org/2001/XMLSchema#');
        this.DC = $rdf.Namespace('http://purl.org/dc/terms/');

        // Declare Shape Graph
        this.shape = $rdf.graph();

        // Declare Data Graph
        this.data = $rdf.graph();

        // Temporary form
        this.tempForm = [];

        // Initialize Form Data, all user entries are stored here
        window.localStorage.setItem('DhpFormData', JSON.stringify({}));

        this.classLinks = [];
        this.guessedRoot = [];
        this.guessedCl = [];
        this.targetClasses = [];

        this.prefixes = [];
    }

    set dataContent(value) {
        this._dataContent = value;
    }

    set shapeContent(value) {
        this._shapeContent = value;
    }

    set queryContent(value) {
        this._queryContent = value;
    }

    get dataContent() {
        return this._dataContent;
    }

    get queryContent() {
        return this._queryContent;
    }

    get shapeContent() {
        return this._shapeContent;
    }

    set queryResult(value) {
        this._queryResult = value;
    }

    get queryResult() {
        return this._queryResult;
    }

    // Create unique id for element, without -
    guidGenerator() {
        const S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return ("uuid" + S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
    };

    /**
     * Create a new html tag for given input data
     * 
     * @param {*} properties 
     * 
     * @returns html tag
     */
    createHtmlTag(properties) {

        let tag = document.createElement('div'); // default element

        const id = this.guidGenerator();

        const cl = (properties.class !== undefined && properties.class.length > 0) ? properties.class[0] : properties.class;
        const ls = JSON.parse(localStorage.getItem("DhpFormData"));

        switch (properties.type) {
            case 'input':
                tag = document.createElement('dhpc-input-field');

                tag.setAttribute('class', '');
                tag.setAttribute('placeholder', properties.tooltip);
                tag.setAttribute('name', properties.name);
                tag.setAttribute('value', properties.values);
                tag.setAttribute('contenteditable', 'true');
                tag.setAttribute('spellcheck', 'false');
                tag.setAttribute('id', id);
                tag.setAttribute('data-id', id);
                tag.setAttribute('title', properties.label);

                if (properties.label === undefined || properties.label === '') {
                    tag.setAttribute('hidden', true);
                }

                // Set default value
                ls[id] = {
                    'id': id,
                    'targetRoot': properties.node,
                    'targetClass': cl,
                    'targetPath': properties.path,
                    'targetType': properties.shaclType,
                    'targetValue': properties.values
                };

                break;
            case 'inputClass':
                tag = document.createElement('dhpc-input-field');
                tag.setAttribute('class', '');
                tag.setAttribute('data-bs-toggle', 'collapse');
                tag.setAttribute('data-id', id);
                tag.setAttribute('placeholder', properties.tooltip);
                tag.setAttribute('name', properties.name);
                tag.setAttribute('value', properties.values);
                tag.setAttribute('contenteditable', 'true');
                tag.setAttribute('id', id);
                tag.setAttribute('title', properties.label);
                tag.setAttribute('hidden', true);

                tag.setAttribute('aria-expanded', false);
                tag.setAttribute('aria-controls', 'collapse' + id);

                // Set default value
                ls[id] = {
                    'id': id,
                    'targetRoot': properties.node,
                    'targetClass': cl,
                    'targetPath': properties.path,
                    'targetType': properties.shaclType,
                    'targetValue': properties.values
                };

                break;
            case 'date':
                tag = document.createElement('dhpc-datepicker-field');
                tag.setAttribute('class', '');
                tag.setAttribute('placeholder', properties.tooltip);
                tag.setAttribute('name', properties.name);
                tag.setAttribute('value', properties.values);
                tag.setAttribute('contenteditable', 'true');
                tag.setAttribute('spellcheck', 'false');
                tag.setAttribute('id', id);
                tag.setAttribute('value', properties.values);
                tag.setAttribute('data-id', id);
                tag.setAttribute('title', properties.label);

                // Set default value
                ls[id] = {
                    'id': id,
                    'targetRoot': properties.node,
                    'targetClass': cl,
                    'targetPath': properties.path,
                    'targetType': properties.shaclType,
                    'targetValue': properties.values
                };

                break;
        }

        // Update local storage
        window.localStorage.setItem("DhpFormData", JSON.stringify(ls));

        return tag;
    }

    /**
     * Get property type of given node
     * 
     * @param {*} node 
     * 
     * @returns type of node
     */
    getPropertyTypeOfNode(node) {

        const hasDatatype = this.shape.each(node, this.SHACL('datatype')).length > 0;
        const hasNodeKind = this.shape.each(node, this.SHACL('nodeKind')).length > 0;
        // var hasPattern = shape.each(node, SHACL('pattern')).length > 0;
        const hasClass = this.shape.each(node, this.SHACL('class')).length > 0;
        const hasOr = this.shape.each(node, this.SHACL('or')).length > 0;
        const hasValue = this.shape.each(node, this.SHACL('hasValue')).length > 0;

        /*
        sh:path rdfs:label ;
					sh:datatype rdf:langString ;
                    sh:or (
                            [ sh:languageIn ("de") ]
                            [ sh:languageIn ("en") ]
                          )
        */
        if (hasOr && hasDatatype) {
            return this.propType.single;
        }

        if (hasOr) {
            return this.propType.multipleOr;
        }

        if (hasDatatype) {
            return this.propType.single;
        }

        if (hasClass) {
            return this.propType.class;
        }

        if (hasNodeKind) { // hasPattern  (optional)
            return this.propType.uri;
        }

        if (hasValue) {
            return this.propType.enumeration;
        }

    }

    /**
     * 
     * 
     * @param {*} datatype 
     * 
     * @returns type of html input
     */
    getHtmlInputTypeForDatatype(datatype) {
        switch (datatype[0].value) {
            case this.XSD('string').value:
                return "input";
            case this.XSD('dateTime').value:
                return "date";
            case this.RDF('langString').value:
                return "input";
            default:
                return "input";
        }
    }

    getEntity(uri) {
        return uri.slice(uri.indexOf('#') + 1, uri.length);
    }

    generatePropFields(root, node, path, type) {

        if (Array.isArray(root)) {
            root = root[0];
        }

        if (Array.isArray(path)) {
            path = path[0];
        }

        switch (type) {
            case this.propType.single:
                var hasLabel = this.shape.each(node, this.RDF('label')).length > 0;
                var hasName = this.shape.each(node, this.SHACL('name')).length > 0;
                var hasDescription = this.shape.each(node, this.DC('description')).length > 0;

                var label = this.getEntity(path.value);

                if (hasLabel) {
                    label = this.shape.each(node, this.RDF('label'))[0].value;
                }

                if (hasName) {
                    label = this.shape.each(node, this.SHACL('name'))[0].value;
                }

                var description = "";
                if (hasDescription) {
                    description = this.shape.each(node, this.DC('description'))[0].value;
                }

                const datatype = this.shape.each(node, this.SHACL('datatype'));

                var cl = this.shape.each(root, this.SHACL('targetClass'));

                var data = {
                    'root': null,
                    'node': root,
                    'path': path,
                    'name': path.value,
                    'class': cl,
                    'label': label,
                    'tooltip': description,
                    'values': [],
                    'type': this.getHtmlInputTypeForDatatype(datatype),
                    'shaclType': type
                };

                this.tempForm.push(data);
                break;
            case this.propType.class: // 3
                // build list of links to classes

                var cl = this.shape.each(node, this.SHACL('class'))[0].value;

                var data = {
                    'root': root,
                    'node': root,
                    'path': path,
                    'name': path.value,
                    'property': path.value,
                    'class': cl,
                    'values': [],
                    'tooltip': 'Click to edit',
                    'label': path.value,
                    'shaclType': type,
                    'type': 'inputClass'
                };

                this.classLinks.push(data);
                this.guessedRoot.push(root.value);
                this.guessedCl.push(cl);

                this.tempForm.push(data);

                break;
            case this.propType.uri:
                var hasLabel = this.shape.each(node, this.RDF('label')).length > 0;
                var hasName = this.shape.each(node, this.SHACL('name')).length > 0;
                var hasDescription = this.shape.each(node, this.DC('description')).length > 0;

                var label = this.getEntity(path.value);
                if (hasLabel) {
                    label = this.shape.each(node, this.RDF('label'))[0].value;
                }

                if (hasName) {
                    label = this.shape.each(node, this.SHACL('name'))[0].value;
                }

                if (!hasLabel && !hasName) {
                    label = undefined; // hide
                }

                var description = "";
                if (hasDescription) {
                    description = this.shape.each(node, this.DC('description'))[0].value;
                }

                const nodeKind = this.shape.each(node, this.SHACL('nodeKind'));
                const pattern = this.shape.each(node, this.SHACL('pattern'));

                let val = "";
                if (pattern.length > 0) {
                    val = pattern[0].value;
                }

                var cl = this.shape.each(root, this.SHACL('targetClass'));

                var data = {
                    'root': null,
                    'node': root,
                    'path': path,
                    'class': cl,
                    'name': path.value,
                    'label': label,
                    'tooltip': description,
                    'values': val,
                    'validator': 'startsWith',
                    'type': 'input',
                    'shaclType': type
                };

                this.tempForm.push(data);

                break;
            case this.propType.multipleOr:
                const childs = this.shape.each(node, this.SHACL('or'))[0];

                let isStringUri = false;

                const self = this;

                if (childs.elements.length === 2) {
                    // check if there is a combination of string and uri
                    // we need only 1 input field for this

                    let stringSeen = false;
                    let uriSeen = false;

                    if (childs.termType === 'Collection') {
                        childs.elements.forEach(function (v, k) {
                            if (v.termType === 'BlankNode') {
                                const bNode = new $rdf.BlankNode(v.value);

                                const propertyType = self.getPropertyTypeOfNode(bNode);

                                if (propertyType === self.propType.single) {
                                    stringSeen = true;
                                }

                                if (propertyType === self.propType.uri) {
                                    uriSeen = true;
                                }
                            }
                        });

                    }

                    isStringUri = stringSeen && uriSeen;

                }

                if (childs.termType === 'Collection') {
                    childs.elements.forEach(function (v, k) {
                        if (v.termType === 'BlankNode') {
                            const bNode = new $rdf.BlankNode(v.value);

                            const propertyType = self.getPropertyTypeOfNode(bNode);

                            if (isStringUri) {
                                // create only one input field of type string
                                if (propertyType === self.propType.single) {
                                    var path = self.shape.each(node, self.SHACL('path'));

                                    self.generatePropFields(root, bNode, path, propertyType);
                                }
                            } else {
                                var path = self.shape.each(node, self.SHACL('path'));

                                self.generatePropFields(root, bNode, path, propertyType);
                            }

                        }
                    });

                }
                break;
            default:
                break;
        }

    }

    shaclProperties() {
        const container = document.createElement('div');
        container.id = 'metadata';

        var self = this;

        this.shape.each(null, this.RDF('type'), this.SHACL('NodeShape')).forEach(function (n) {

            let subject = null;
            if (n.isBlank === 1) {
                subject = new $rdf.BlankNode(n.value);
            } else {
                subject = $rdf.NamedNode.fromValue(n.value);
            }

            const targetClass = self.shape.each(subject, self.SHACL('targetClass'));

            if (targetClass.length > 0) {
                self.targetClasses.push({
                    'subject': subject.value,
                    'targetClass': targetClass[0].value
                });
            }

            self.shape.each(subject, self.SHACL('property')).forEach(function (p) {
                if (p.isBlank === 1) {
                    const bNode = new $rdf.BlankNode(p.value);

                    const propertyType = self.getPropertyTypeOfNode(bNode);

                    const path = self.shape.each(bNode, self.SHACL('path'));

                    self.generatePropFields(subject, bNode, path, propertyType);
                }
            });

        });

        var self = this;

        this.guessedCl.forEach(function (v, k) {
            const itemInArray = (element) => element === v;

            self.guessedRoot.forEach(function (v1, k1) {
                const inArray = self.guessedRoot.findIndex(itemInArray);

                if (inArray != -1) {
                    self.guessedRoot.splice(inArray, 1);
                }
            })

        })

        const filteredGuessedRoot = this.guessedRoot.filter((data, index) => {
            return this.guessedRoot.indexOf(data) === index;
        });

        filteredGuessedRoot.forEach(function (v, k) {
            container.appendChild(self.createFormElement(v, true));
        });

        return container;
    }

    createFormElement(v, c) {

        const container = document.createElement('div');

        // const headline = document.createElement('h3');
        // headline.innerText = v;

        // container.appendChild(headline);

        const filteredForm = this.tempForm.filter((data, index) => {
            return data.node.value === v;
        });

        const formcontainer = document.createElement('form'); // start with <form></form>

        for (let i = 0; i < filteredForm.length; i++) {
            const tag = this.createHtmlTag(filteredForm[i]);

            formcontainer.appendChild(tag); // Append html tag to formcontainer

            if (c) {
                if (filteredForm[i].type === 'inputClass') {
                    // append form
                    // lookup targetClass

                    let target = this.targetClasses.filter((da) => {
                        return da.targetClass === filteredForm[i].class;
                    });
                    if (Array.isArray(target) && target.length > 0) {
                        target = target[0];
                    }

                    const c2tag = document.createElement('div');
                    c2tag.setAttribute('class', 'collapse show');
                    c2tag.id = 'collapse' + tag.getAttribute('data-id');

                    const c3tag = document.createElement('div');
                    c3tag.setAttribute('class', 'card card-body');
                    c3tag.appendChild(this.createFormElement(target.subject, false));

                    c2tag.appendChild(c3tag);

                    formcontainer.appendChild(c2tag);
                }
            }

        }
        /*	var submitButon = document.createElement('button');
        	submitButon.setAttribute('class', 'btn btn-primary');
        	submitButon.setAttribute('type', 'submit');
        	submitButon.innerText = 'Submit';
        	formcontainer.appendChild(submitButon); */

        container.appendChild(formcontainer);

        return container;
    }

    toTurtle() {
        let outString = "";

        const self = this;
        const dhpFormData = JSON.parse(localStorage.getItem("DhpFormData"));

        this.targetClasses.forEach(function (cl, k) {
            self.targetClasses[k]['subject'] = new $rdf.BlankNode();

            // @todo daniel
            // auch hier die prefixes berücksichtigen
            const statement = new $rdf.Statement(
                self.targetClasses[k]['subject'],
                self.RDF('type'),
                new $rdf.NamedNode(self.targetClasses[k]['targetClass'])
            )

            // outString += statement.toString() + "\n";

            // schaue nach Class Links
            const links = self.tempForm.filter((data) => {
                return data.class === self.targetClasses[k]['targetClass'];
            });

            if (links.length === 1) {
                var found = false;

                // Lookup links[0].path.value in dhpFormData
                // @todo prüfe auf BlankNode und NamedNode
                for (const [key, value] of Object.entries(dhpFormData)) {
                    if (value.targetPath.value === links[0].path.value && ("subject" in value)) {
                        found = true;
                        const statement = new $rdf.Statement(
                            new $rdf.NamedNode(value.subject.value),
                            new $rdf.NamedNode(links[0].path.value),
                            new $rdf.BlankNode(value.targetValue.value)
                        )
                        outString += statement.toString() + "\n";
                    }

                    if (value.targetPath.value === links[0].path.value && !("subject" in value)) {
                        var root = self.prefixes.filter(a => a.prefix === "root");
                        var child = self.prefixes.filter(b => b.prefix === self.getEntity(links[0].path.value));
                        if (root !== undefined && root.length === 1 && child !== undefined && child.length === 1) {
                            const statement = new $rdf.Statement(
                                new $rdf.NamedNode(root[0].subject.value),
                                new $rdf.NamedNode(links[0].path.value),
                                new $rdf.BlankNode(child[0].subject.value)
                            )
                            outString += statement.toString() + "\n";

                            found = true;
                        }

                    }
                }

                // Create a new BlankNode
                if (!found) {
                    const statement = new $rdf.Statement(
                        new $rdf.BlankNode,
                        new $rdf.NamedNode(links[0].path.value),
                        self.targetClasses[k]['subject']
                    )
                    outString += statement.toString() + "\n";
                }

            }
        })

        // due to a bug in rdflib.js we cannot use serialize
        for (const [key, value] of Object.entries(dhpFormData)) {

            if (value.targetClass !== undefined) {

                const tcl = this.targetClasses.filter((data) => {
                    return data.targetClass === value.targetClass.value
                });

                if (tcl.length == 0) {
                    // ??????
                } else {
                    var subject = tcl[0].subject;

                    if ('subject' in value) {
                        subject = value.subject;
                    }

                    if (value.targetType === this.propType.single) {
                        // data.add(subject, value.targetPath, $rdf.lit(value.targetValue));

                        var statement = '';

                        if (subject.termType === 'BlankNode') {
                            statement = new $rdf.Statement(
                                new $rdf.BlankNode(subject.value),
                                new $rdf.NamedNode(value.targetPath.value),
                                $rdf.lit(value.targetValue.value)
                            )
                        } else {
                            statement = new $rdf.Statement(
                                new $rdf.NamedNode(subject.value),
                                new $rdf.NamedNode(value.targetPath.value),
                                $rdf.lit(value.targetValue.value)
                            )
                        }

                        outString += statement.toString() + " \n";

                    }

                    if (value.targetType === this.propType.uri) {
                        var statement = '';
                        if (subject.termType === 'BlankNode') {

                            statement = new $rdf.Statement(
                                new $rdf.BlankNode(subject.value),
                                new $rdf.NamedNode(value.targetPath.value),
                                new $rdf.NamedNode(value.targetValue.value)
                            )
                        } else {
                            statement = new $rdf.Statement(
                                new $rdf.NamedNode(subject.value),
                                new $rdf.NamedNode(value.targetPath.value),
                                new $rdf.NamedNode(value.targetValue.value)
                            )
                        }
                        outString += statement.toString() + " \n";

                    }

                    if (value.targetType === this.propType.class) {

                        const statement = new $rdf.Statement(
                            new $rdf.NamedNode(subject.value),
                            new $rdf.NamedNode(value.targetPath.value),
                            value.targetValue
                        )
                        outString += statement.toString() + " \n";

                    }
                }

            }

        }

        return outString;
    }

    async executeSparql(query) {
        var eq = $rdf.SPARQLToQuery(query, true, this.data)
        return this.data.querySync(eq);
    }

    async fetchAll() {
        const [dataFetcher, shapeFetcher, queryFetcher] = await Promise.all([
            (this.dataUrl !== undefined && this.dataUrl.length > 0) ? fetch(this.dataUrl).then(d => d.text()) : undefined,
            fetch(this.shapeUrl).then(d => d.text()),
            (this.queryUrl !== undefined & this.queryUrl.length > 0) ? fetch(this.queryUrl).then(d => d.text()) : undefined
        ]);

        this.dataContent = dataFetcher;
        this.shapeContent = shapeFetcher;
        this.queryContent = queryFetcher;

        if (dataFetcher !== undefined) {
            $rdf.parse(this.dataContent, this.data, this.base, "text/turtle");
        }

        if (queryFetcher !== undefined) {
            this.queryResult = await this.executeSparql(this.queryContent);
            var queryResultReadable = '';
            this.queryResult.forEach(function (t) {
                var keys = Object.keys(t);
                keys.forEach(function (key) {
                    queryResultReadable += key + ": " + t[key].value + "</br>";
                })
            })
        }

        return [dataFetcher, shapeFetcher, queryFetcher, this.queryResult, queryResultReadable];
    }

    async fetchAll2() {
        const [shapeFetcher] = await Promise.all([
            fetch(this.shapeUrl).then(d => d.text())
        ]);

        this.dataContent = '';
        this.shapeContent = shapeFetcher;
        this.queryContent = '';

        return [shapeFetcher];
    }
    queryAgain() {
        (async () => {
            if (this.queryContent !== undefined && this.queryContent !== '') {
                this.queryResult = await this.executeSparql(this.queryContent);
                var queryResultReadable = '';
                this.queryResult.forEach(function (t) {
                    var keys = Object.keys(t);
                    keys.forEach(function (key) {
                        queryResultReadable += key + ": " + t[key].value + "</br>";
                    })
                })
            }
        });
    }


    parseShacl(done, error) {
        var self = this;
        $rdf.parse(self.shapeContent, self.shape, self.base, "text/turtle");

        var f = self.shaclProperties();
        done(f);
    }

    lookupPrefix(lookupKey) {
        var prefix = Object.keys(this.queryResult[0])[0];
        var t = this.queryResult[0];
        var keys = Object.keys(t);
        var self = this;
        keys.forEach(function (key) {
            var skey = key.substring(1, key.length);
            if (skey.length >= 3 && skey.endsWith(lookupKey)) {
                if (!self.prefixes.includes(lookupKey)) {
                    self.prefixes.forEach(function (k) {
                        if (k.includes(lookupKey) && k.length > lookupKey) {
                            // console.log("Teil von " + lookupKey);
                        } else {
                            // console.log("genau");
                        }
                    })
                    // console.log("Gefundener Key: " + skey);
                    self.prefixes.push(lookupKey);
                }
            }
        })
    }

    /**
     * Prefill form with data from sparql result
     * 
     * NOTE: key must match sh:path (targetPath)
     * NOTE: result must have a ?s = subject property
     */
    prefillForm(shadowDom) {
        var dhpFormData = JSON.parse(localStorage.getItem("DhpFormData"));
        var self = this;

        console.log(self.queryResult);
        console.log(self.queryResult[0]['?root']);

        if (self.queryResult !== undefined) {
            self.prefixes.push({
                prefix: 'root',
                subject: self.queryResult[0]['?root']
            });

            console.log(self.prefixes);

            // Damit die Pfade richtig sind, müssen wir die Subjects anhand der prefixes updaten
            for (const [keya, valuea] of Object.entries(dhpFormData)) {
                // this.lookupPrefix(self.getEntity(valuea.targetPath.value))
                var lookupKey = self.getEntity(valuea.targetPath.value);
                console.log(lookupKey);
                console.log(Object.keys(this.queryResult[0]));
                var prefix = Object.keys(this.queryResult[0]).filter(a => a.substring(1, a.length) === lookupKey);
                console.log("Prefix: " + prefix);
                if (prefix.length == 1) {
                    self.prefixes.push({
                        prefix: prefix[0].substring(1, prefix[0].length),
                        subject: self.queryResult[0][prefix[0]]
                    });
                }
            }


            // 2. Über dhpFormData iterieren und im targetPath nach dem Prefix suchen.

            for (const [keya, valuea] of Object.entries(dhpFormData)) {
                this.queryResult.forEach(function (t) {
                    var keys = Object.keys(t);
                    console.log(keys);
                    keys.forEach(function (key) {
                        // find value

                        if (key.endsWith(self.getEntity(valuea.targetPath.value))) {
                            var v = t[key];

                            console.log(v);
                            // Update value in storage
                            dhpFormData[keya]['targetValue'] = v;
                            dhpFormData[keya]['subject'] = t['?s'];

                            var newkey = key.replace(self.getEntity(valuea.targetPath.value), "");
                            newkey = newkey.substring(1, newkey.length - 1); // without leading ? and _

                            console.log(newkey);

                            var prefix = self.prefixes.filter(a => a.prefix === newkey);
                            if (prefix.length == 1) {
                                dhpFormData[keya]['subject'] = prefix[0].subject;
                            }

                            // Update value of html element
                            shadowDom.getElementById(keya).value = v.value;
                            console.log(shadowDom.getElementById(keya));
                            window.localStorage.setItem("DhpFormData", JSON.stringify(dhpFormData));
                        }
                    })
                })
            }

            console.log(dhpFormData);
        }
    }
}