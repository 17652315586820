import { LitElement, html } from 'lit-element';

class DhpcInputIriField extends LitElement {
  
  static get properties() {
    return {
      id: { type: String },
      data: { attribute: false },
      name: { type: String },
      title: { type: String },
      placeholder: { type: String },
      label: { type: String },
      value: { type: String }
    }
  }

  constructor() {
    super();
    this.id = '';
    this.data = { id: ''};
    this.name = '';
    this.title = '';
    this.label = '';
    this.placeholder = '';
  }

  render() {
    return html`
      <style>
        @import "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css";
      </style>
      <div class="form-group">
      <label for="${this.id}">${this.title}</label>
      <input id="${this.id}" class="form-control" name="${this.name}" tooltip="${this.tooltip}" placeholder="${this.placeholder}" value="${this.value}">
      <small id="emailHelp" class="form-text text-muted">${this.value}</small>

    </div>
      
    `;
  }
}

customElements.define('dhpc-inputiri-field', DhpcInputIriField);