/*
 Copyright 2021 Daniel Schlager

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/

import {
	Builder
} from './builder.js';

import * as $rdf from 'rdflib';

const template = document.createElement("template");
template.innerHTML = `<style>
@import "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css";
</style><style>.card-body { padding: 0; } .card { border: 0px; }</style>`;

/**
 * @access public
 */
class DhpcMetadataEditor extends HTMLElement {
	constructor() {
		super();

		this._shadow = this.attachShadow({
			mode: "open"
		});

		this._shadow.appendChild(template.content.cloneNode(true));

		this._base = '';
		this._queryUrl = '';
		this._dataUrl = '';
		this._shapeUrl = '';
		this._dataContent = '';
		this._queryContent = '';
		this._queryResult = '';

		var self = this;

		this.onParseFinished = function (cb) {
			self._shadow.appendChild(cb);

			// Attach Event Listeners
			var dhpFormData = JSON.parse(localStorage.getItem("DhpFormData"));
			for (const [key, value] of Object.entries(dhpFormData)) {

				if (value.targetType === self.builder.propType.single || value.targetType === self.builder.propType.uri) {
					var slot = self._shadow.querySelector('#' + key);
					slot.addEventListener("val-change", function (event) {
						var ls = JSON.parse(localStorage.getItem("DhpFormData"));
						ls[key]["targetValue"] = $rdf.lit(event.detail.value);
						localStorage.setItem("DhpFormData", JSON.stringify(ls));

						// Fire onTurtleUpdate event
						self.dispatchEvent(new CustomEvent('onTurtleUpdate', {
							detail: self.builder.toTurtle()
						}));

						// Fire onUpdate event
						self.dispatchEvent(new CustomEvent('onUpdate', {
							detail: ls
						}));

					});
				}

				if (value.targetType === self.builder.propType.class) {
					var collapse = self._shadow.querySelector('#' + key);
					collapse.addEventListener('click', function (evt) {
						var e = self._shadow.querySelector("#collapse" + key);
						e.classList.toggle("show");
					});
				};


			}

			self.builder.prefillForm(self._shadow);
		};

		this.onParserError = function (error) {
			console.log(error);
		};

		
	}

	set base(value) {
		this._base = value;
		// this.fetchAndParse();
	}

	get base() {
		return this._base;
	}

	set queryUrl(value) {
		this._queryUrl = value;
		// this.fetchAndParse();
	}

	get queryUrl() {
		return this._queryUrl;
	}

	set dataUrl(value) {
		this._dataUrl = value;
		// this.fetchAndParse();
	}

	get dataUrl() {
		return this._dataUrl;
	}

	set shapeUrl(value) {
		this._shapeUrl = value;
		// this.fetchAndParse();
	}

	get shapeUrl() {
		return this._shapeUrl;
	}

	set dataContent(value) {
		this._dataContent = value;
		this.builder.dataContent = value;
		this.parse();
	}

	get dataContent() {
		return this._dataContent;
	}

	set queryContent(value) {
		this._queryContent = value;
		this.builder.queryContent = value;
		this.parse();
	}

	get queryContent() {
		return this._queryContent;
	}

	set queryResult(value) {
		this._queryResult = value;
	}

	get queryResult() {
		return this._queryResult;
	}
	connectedCallback() {
		if (this.hasAttribute("base")) {
			this.base = this.getAttribute("base");
		} else {
			this.base = 'https://dhplus.sbg.ac.at/'; // fallback to default
		}

		if (this.hasAttribute("query")) {
			var query = this.getAttribute("query");
			if (query.startsWith("http")) {
				this.queryUrl = this.getAttribute("query");
			}
		}

		if (this.hasAttribute("data")) {
			this.dataUrl = this.getAttribute("data");
		}

		if (this.hasAttribute("shape")) {
			this.shapeUrl = this.getAttribute("shape");
		}

		// this.fetchAndParse();
	}

	parse() {
		this.builder = new Builder(this.base, undefined, this.shapeUrl, undefined);
		this.builder.queryResult = this.queryResult;

		(async () => {
			const [shapeFetcher] = await this.builder.fetchAll2();

			// Fire onShapeLoaded event
			this.dispatchEvent(new CustomEvent('onShapeLoaded', {
				detail: shapeFetcher
			}));

			this.builder.parseShacl(this.onParseFinished, this.onParserError);
		})();

	}

	fetchAndParse() {
		console.log("fetchAndParse");
		this.builder = new Builder(this.base, this.dataUrl, this.shapeUrl, this.queryUrl);

		(async () => {
			const [dataFetcher, shapeFetcher, queryFetcher, queryResultFetcher, queryResultReadable] = await this.builder.fetchAll();

			// Fire onShapeLoaded event
			this.dispatchEvent(new CustomEvent('onShapeLoaded', {
				detail: shapeFetcher
			}));

			// Fire onDataLoaded event
			this.dispatchEvent(new CustomEvent('onDataLoaded', {
				detail: dataFetcher
			}));

			// Fire onQueryLoaded event
			this.dispatchEvent(new CustomEvent('onQueryLoaded', {
				detail: queryFetcher
			}));

			// Fire onQueryResultLoaded event
			this.dispatchEvent(new CustomEvent('onQueryResultLoaded', {
				detail: queryResultReadable
			}));

			this.builder.parseShacl(this.onParseFinished, this.onParserError);
		})();
	}

	
}

customElements.define('dhpc-metadata-editor', DhpcMetadataEditor);