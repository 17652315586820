import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';
import { LitElement, html } from 'lit-element';

class DhpcDatepickerField extends LitElement {
  
  static get properties() {
    return {
      id: { type: String },
      data: { attribute: false },
      name: { type: String },
      title: { type: String },
      placeholder: { type: String },
      label: { type: String },
      value: { type: String }
    }
  }

  constructor() {
    super();
    this.id = '';
    this.data = { id: ''};
    this.name = '';
    this.title = '';
    this.label = '';
    this.placeholder = '';
    this.value = '';
  }

  onInput(event) {
    this.value = this.inputEl.value;
    
    this.dispatchEvent(
      new CustomEvent("val-change", {
        detail: {
          value: event.composedPath()[0].value
        }
      }));

  }

  get inputEl() {
    return this.shadowRoot.getElementById(this.id);
  }
  
  render() {
    return html`
      <style>
        @import "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css";
      </style>
      <div class="form-group">
      <label for="${this.id}">${this.title}</label>
      <vaadin-date-picker @change="${this.onInput}" .value="${this.value}" id="${this.id}" name="${this.name}" tooltip="${this.tooltip}" class="form-control" placeholder="${this.placeholder}"></vaadin-date-picker>
    </div>
      
    `;
  }
}

customElements.define('dhpc-datepicker-field', DhpcDatepickerField);