import {
  LitElement,
  html
} from 'lit-element';

class DhpcInputField extends LitElement {

  static get properties() {
    return {
      id: {
        type: String
      },
      data: {
        attribute: false
      },
      name: {
        type: String
      },
      title: {
        type: String
      },
      placeholder: {
        type: String
      },
      label: {
        type: String
      },
      value: {
        type: String
      },
      hidden: {
        type: Boolean
      }
    }
  }

  constructor() {
    super();
    this.id = '';
    this.data = {
      id: ''
    };
    this.name = '';
    this.title = '';
    this.label = '';
    this.value = '';
    this.placeholder = '';
    this.hidden = false;
  }

  onInput(event) {
    this.value = this.inputEl.value;
    // console.log(this.inputEl.value);

    this.dispatchEvent(
      new CustomEvent("val-change", {
        detail: {
          value: event.composedPath()[0].value
        }
      }));

  }

  get inputEl() {
    return this.shadowRoot.getElementById(this.id);
  }

  render() {
    return html `
      <style>
        @import "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css";
      </style>
      <div class="form-group" style="${this.hidden === true ? 'display: none;' : ''}">
      <label for="${this.id}">${this.title}</label>
      <input id="${this.id}" class="form-control" @input="${this.onInput}" name="${this.name}" .value="${this.value}" tooltip="${this.tooltip}" placeholder="${this.placeholder}">
      <!-- <small id="emailHelp" class="form-text text-muted">${this.value}</small> -->
    </div>
      
    `;
  }
}

customElements.define('dhpc-input-field', DhpcInputField);